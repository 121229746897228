import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import jwt_decode from 'jwt-decode';

//import PageHeader from '../components/PageHeader';
//import { UserListHeader, UserListItem, UserItemModal } from '../components/user/index';
import { StyledButton, CheckBox, Spinner, Input, Icon } from '../../components/UI';
import { fetchPartners } from '../../store/partnerSlice';

const PartnersPage = () => {
    const dispatch = useDispatch();
    const { list, isLoading, error } = useSelector(state => state.partners);
    //const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        dispatch(fetchPartners());
    }, []);

    return (
        <>
            <div className='banks-container'>
                <div className='container-header'>
                    <div className='container-header-name'>Партнеры</div>
                </div>
                <div className='banks-table'>
                    {isLoading && <Spinner />}
                    {list.length > 0 ?
                        <div style={{ display: "flex", flexDirection: "column", gap: 16 }}>

                            {list && list.map(item => {
                                return (
                                    <div key={item.id} style={{ display: 'flex', flexDirection: "row", gap: 8 }}>
                                        <div>{item.id}</div>
                                        <div>{item.firstName}</div>
                                        <div>{item.lastName}</div>
                                        <div>{item.phone}</div>
                                        <div>{item.email}</div>
                                    </div>
                                )
                            })}
                        </div>
                        :
                        <div className='no-items'>Список пока пуст.</div>
                    }
                </div>
            </div>
        </>

    )
}

export default PartnersPage;
