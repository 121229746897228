import React from 'react';
import { BsGear, BsArchive } from "react-icons/bs";

const ActivityTypesListItem = ({ activityType, onEditHandler = () => { }, onSelectHandler = () => { }, selectedItem, onArchiveHandler = () => { } }) => {
    const { id, name } = activityType;
    console.log("+++++ ", selectedItem, selectedItem, id);
    return (
        <div key={id}
            className={"banks-row " + (selectedItem && selectedItem === id ? "selected" : "")}
            onClick={() => onSelectHandler(id)}
            onDoubleClick={() => onEditHandler(id)}
        >
            <div className='bank-id'>{id}</div>
            <div className='bank-name'>{name}</div>
        </div>
    );
};

export default ActivityTypesListItem;

