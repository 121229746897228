import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import jwt_decode from 'jwt-decode';

import { Spinner, StyledButton, Icon } from '../../components/UI';
import ConfirmDeleteModal from '../../components/ConfirmDeleteModal';
import ActivityTypeItemModal from "./ActivityTypeItemModal";
import ActivityTypesListItem from "./ActivityTypesListItem";

import { fetchActivityTypes, addEditActivityType, deleteActivityType } from '../../store/dictionarySlice';

//import "./ActivityFormsPage.modules.css";

const ActivityFormsPage = () => {
    const dispatch = useDispatch();
    const { activityTypes, isLoading, error } = useSelector(state => state.dictionaries);
    const [showModal, setShowModal] = useState(false);
    const [aсtivityTypeForEdit, setAсtivityTypeForEdit] = useState(null);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [itemForDelete, setItemForDelete] = useState(null); //{entityId: 12}
    const [isAdmin, setIsAdmin] = useState(1);
    const [selectedItem, setSelectedItem] = useState(null);

    const onSelectHandler = (activityType) => {
        if (selectedItem && selectedItem === activityType) {
            setSelectedItem(null);
        } else {
            setSelectedItem(activityType);
        };
    };

    useEffect(() => {
        dispatch(fetchActivityTypes());
    }, []);

    const onAddActivityTypeHandler = (activityType) => {
        setAсtivityTypeForEdit(null);
        setShowModal(true);
    };

    const onShowDeleteModalHandler = (id) => {
        setItemForDelete({ entityName: activityTypes.find(p => p.id === id).name, entityId: id })
        setShowDeleteModal(true);
    }

    const onDeleteHandler = (request) => {
        if (request?.answer === true)
            dispatch(deleteActivityType(request?.entityId));
    };

    const onEditHandler = (activityTypeId) => {
        const selectedActivity = activityTypes.find(activityType => activityType.id === activityTypeId);
        setAсtivityTypeForEdit({ ...selectedActivity });
        setShowModal(true);
    };

    const onSaveChangesHandler = (activityTypeItem) => {
        dispatch(addEditActivityType(activityTypeItem));
        setAсtivityTypeForEdit(null);
        setSelectedItem(null);
    };

    return (
        <>
            <div className='banks-container'>
                <div className='container-header'>
                    <div className='container-header-name'>Справочник видов активности</div>
                    {selectedItem ?
                        <>
                            <div className='container-btn'>
                                <StyledButton className={'btn-red'} name=<div className='icon-text'><Icon name='delete' />Удалить</div> onClickHandler={() => onShowDeleteModalHandler(selectedItem)} />
                                <StyledButton name=<div className='icon-text'><Icon name='pencil' />Редактировать</div> onClickHandler={() => onEditHandler(selectedItem)} />
                            </div>
                        </>

                        :
                        <>
                            <StyledButton name=<div className='plus-add-name'><Icon name='plus' />Добавить</div> onClickHandler={onAddActivityTypeHandler} />
                        </>
                    }
                </div>
                <div className='banks-table'>
                    {isLoading && <Spinner />}

                    <div className='banks-header'>
                        <div className='bank-id'>ID</div>
                        <div className='bank-name'>Наименование</div>
                    </div>

                    {activityTypes.length > 0 ?
                        activityTypes.map(activityType => {
                            const { id } = activityType;

                            return (
                                <ActivityTypesListItem
                                    key={id}
                                    activityType={activityType}
                                    selectedItem={selectedItem}
                                    onArchiveHandler={onShowDeleteModalHandler}
                                    onEditHandler={onEditHandler}
                                    onSelectHandler={onSelectHandler}
                                />
                            );
                        })
                        :
                        <div className='no-items'>Список пока пуст.</div>
                    }
                </div>
            </div>
            <ActivityTypeItemModal
                setShowModal={setShowModal}
                showModal={showModal}
                activityType={aсtivityTypeForEdit}
                onSaveChangesHandler={onSaveChangesHandler}
            />
            <ConfirmDeleteModal
                entityName={'вид активности "' + itemForDelete?.entityName + '"'}
                entityId={itemForDelete?.entityId}
                showModal={showDeleteModal}
                setShowModal={setShowDeleteModal}
                onDeleteHandler={onDeleteHandler}
            />
        </>
    )
}

export default ActivityFormsPage;